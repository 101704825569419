var core = require('../../core');


/**
 * The BlurDirFilter applies a Gaussian blur toward a direction to an object.
 *
 * @class
 * @param {number} dirX
 * @param {number} dirY
 * @extends PIXI.AbstractFilter
 * @memberof PIXI.filters
 */
function BlurDirFilter(dirX, dirY)
{
    core.AbstractFilter.call(this,
        // vertex shader
        "attribute vec2 aVertexPosition;\nattribute vec2 aTextureCoord;\nattribute vec4 aColor;\n\nuniform float strength;\nuniform float dirX;\nuniform float dirY;\nuniform mat3 projectionMatrix;\n\nvarying vec2 vTextureCoord;\nvarying vec4 vColor;\nvarying vec2 vBlurTexCoords[3];\n\nvoid main(void)\n{\n    gl_Position = vec4((projectionMatrix * vec3((aVertexPosition), 1.0)).xy, 0.0, 1.0);\n    vTextureCoord = aTextureCoord;\n\n    vBlurTexCoords[0] = aTextureCoord + vec2( (0.004 * strength) * dirX, (0.004 * strength) * dirY );\n    vBlurTexCoords[1] = aTextureCoord + vec2( (0.008 * strength) * dirX, (0.008 * strength) * dirY );\n    vBlurTexCoords[2] = aTextureCoord + vec2( (0.012 * strength) * dirX, (0.012 * strength) * dirY );\n\n    vColor = vec4(aColor.rgb * aColor.a, aColor.a);\n}\n",
        // fragment shader
        "precision lowp float;\n\nvarying vec2 vTextureCoord;\nvarying vec2 vBlurTexCoords[3];\nvarying vec4 vColor;\n\nuniform sampler2D uSampler;\n\nvoid main(void)\n{\n    gl_FragColor = vec4(0.0);\n\n    gl_FragColor += texture2D(uSampler, vTextureCoord     ) * 0.3989422804014327;\n    gl_FragColor += texture2D(uSampler, vBlurTexCoords[ 0]) * 0.2419707245191454;\n    gl_FragColor += texture2D(uSampler, vBlurTexCoords[ 1]) * 0.05399096651318985;\n    gl_FragColor += texture2D(uSampler, vBlurTexCoords[ 2]) * 0.004431848411938341;\n}\n",
        // set the uniforms
        {
            strength: { type: '1f', value: 1 },
            dirX: { type: '1f', value: dirX || 0 },
            dirY: { type: '1f', value: dirY || 0 }
        }
    );

    this.defaultFilter = new core.AbstractFilter();

    /**
     * Sets the number of passes for blur. More passes means higher quaility bluring.
     *
     * @member {number}
     * @default 1
     */
    this.passes = 1;

    /**
     * Sets the X direction of the blur
     *
     * @member {number}
     * @default 0
     */
    this.dirX = dirX || 0;

    /**
     * Sets the Y direction of the blur
     *
     * @member {number}
     * @default 0
     */
    this.dirY = dirY || 0;

    this.strength = 4;
}

BlurDirFilter.prototype = Object.create(core.AbstractFilter.prototype);
BlurDirFilter.prototype.constructor = BlurDirFilter;
module.exports = BlurDirFilter;

BlurDirFilter.prototype.applyFilter = function (renderer, input, output, clear) {

    var shader = this.getShader(renderer);

    this.uniforms.strength.value = this.strength / 4 / this.passes * (input.frame.width / input.size.width);

    if (this.passes === 1) {
        renderer.filterManager.applyFilter(shader, input, output, clear);
    } else {
        var renderTarget = renderer.filterManager.getRenderTarget(true);

        renderer.filterManager.applyFilter(shader, input, renderTarget, clear);

        for(var i = 0; i < this.passes-2; i++)
        {
            //this.uniforms.strength.value = this.strength / 4 / (this.passes+(i*2)) * (input.frame.width / input.size.width);
            renderer.filterManager.applyFilter(shader, renderTarget, renderTarget, clear);
        }

        renderer.filterManager.applyFilter(shader, renderTarget, output, clear);

        renderer.filterManager.returnRenderTarget(renderTarget);
    }
};


Object.defineProperties(BlurDirFilter.prototype, {
    /**
     * Sets the strength of both the blur.
     *
     * @member {number}
     * @memberof PIXI.filters.BlurDirFilter#
     * @default 2
     */
    blur: {
        get: function ()
        {
            return this.strength;
        },
        set: function (value)
        {
            this.padding = value * 0.5;
            this.strength = value;
        }
    },
    /**
     * Sets the X direction of the blur.
     *
     * @member {number}
     * @memberof PIXI.filters.BlurYFilter#
     * @default 0
     */
    dirX: {
        get: function ()
        {
            return this.dirX;
        },
        set: function (value)
        {
            this.uniforms.dirX.value = value;
        }
    },
    /**
     * Sets the Y direction of the blur.
     *
     * @member {number}
     * @memberof PIXI.filters.BlurDirFilter#
     * @default 0
     */
    dirY: {
        get: function ()
        {
            return this.dirY;
        },
        set: function (value)
        {
            this.uniforms.dirY.value = value;
        }
    }
});
